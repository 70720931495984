<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import MainHeader from "../AppHeader";
    import MainFooter from "../AppFooter";
    import compact from "lodash/compact";

    export default {
        name: "Main",

        components: {
            MainHeader,
            MainFooter
        },


        computed: {
            defaultActive: function () {
                return compact(this.$router.currentRoute.path.split("/"))[0];
            }
        }
    }
</script>


<template>
    <el-container id="viewport" direction="vertical">
        <main-header></main-header>
        <el-container>
            <el-aside width="150px">
                <h1 class="aside-header">Цеха</h1>
                <el-menu :router="true" :default-active="defaultActive">
                    <el-menu-item index="vendor" :route="{name:'vendor-list'}">
                        <el-icon class="el-icon-school"></el-icon>
                        Цеха
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
        <main-footer></main-footer>
    </el-container>
</template>


<style scoped lang="scss">
    @import "../../scss/element";

    .el-aside {
        border-right: 1px solid #eeeeee;
        padding-top: 20px;
    }

    .el-menu {
        border-right: none;
        &-item {
            &.is-active {
                font-weight: 700;
                background-color: $--dropdown-menuItem-hover-fill;
            }
        }
    }
</style>