<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Vendor from "../../models/Vendor";
    import BaseForm from "../../components/Form";

    export default {
        name: "Form",
        components: {
            BaseForm
        },
        props: {
            id: Number | String
        },
        data() {
            return {
                model: new Vendor({
                    id: this.id
                })
            }
        },
        computed: {
            title: (vm) => vm.model.id ? vm.model.$.name : 'Создать'
        }
    }
</script>


<template>
    <div class="humanize-container" v-loading="model.loading">
        <base-form :title="title"
                   backTitle="Цеха"
                   :model="model">

            <el-form-item label="Название"
                          :error="model.errors.name"
                          :show-message="!!model.errors.name"
                          required="">
                <el-input v-model="model.name"
                          placeholder="например: Сыроварня"
                          clearable
                          :maxlength="120"></el-input>
            </el-form-item>


            <el-form-item label="Алиас (латиницей, без пробелов)"
                          :error="model.errors.alias"
                          :show-message="!!model.errors.alias">
                <el-input v-model="model.alias"
                          placeholder="например: sirovarna, оставьте пустым чтобы создать автоматически..."
                          clearable=""
                          :maxlength="250"></el-input>
            </el-form-item>

            <el-form-item label="Текст описания, можно html"
                          :error="model.errors.full_text"
                          :show-message="!!model.errors.full_text">
                <el-input type="textarea"
                          v-model="model.full_text"
                          placeholder="Данный текст может отображаться, например, в карточке Производителя..."
                          :autosize="{ minRows: 5}"></el-input>
            </el-form-item>
        </base-form>
    </div>
</template>


<style scoped lang="scss">
</style>