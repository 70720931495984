<script>
    /**
     * The list of Products
     *
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import BaseStore from "../../models/BaseStore";
    import Vendor from "../../models/Vendor";
    import filter from "lodash/filter";
    import SimpleList from "../../components/SimpleList";


    export default {
        name: "VendorsList",
        components: {
            SimpleList
        },

        data() {
            return {
                store: new BaseStore([], {
                    model: Vendor,
                    entityName: 'vendor',
                    autoLoad: true,
                    fetchParams: {
                        limit: 0,
                        sort: '-updated_at',
                        fields: 'id,name,alias'
                    }
                })
            }
        }
    }
</script>


<template>
    <div class="products-list"
         v-loading="store.loading">

        <div class="humanize-container--short">
            <simple-list :store="store"
                         details-route="vendor-edit"
                         create-route="vendor-create"
                         filter-placeholder="Фильтр по названию...">
            </simple-list>
        </div>

    </div>
</template>


<style lang="scss" scoped>
    @import "../../scss/element";

    .thumb {
        width: 50px;
        height: 50px;
    }

    .price {
        white-space: nowrap;
        font-weight: 600;
    }

    .product-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.1rem;
        display: block;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 400;
        color: $--color-primary;
        text-decoration: none;
    }

    .metas {
        font-size: .8rem;
        color: gray;
    }

    .toolbar {

        height: 60px;
        align-items: center;
        justify-content: space-between;

        > * + * {
            margin-left: 10px;
        }
    }

</style>